.cardContainer {
  color: var(--color-a);
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-a);
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
}

.cardIndex {
  margin-right: 5px;
}

.cardDetails{
  display: flex;
  flex-direction: column;  
  margin-left:5px;
  position: relative;
  width: 100%;
}

.price {
  font-size: 0.875rem;
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 0;
}

.photo {
  width: 20%;
}

.homesiteDetails {
  font-size: 0.75rem;
}
