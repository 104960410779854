.MapModeNav {
  background-color: var(--color-a);
  border: 2px solid #fff;
  border-radius: 5px;
  left: 2rem;
  padding: 0.5rem;
  pointer-events: all;
  position: absolute;
  transition: 0.3s linear;
  top: 230px;
  width: 212px;
}

.collapse {
  transform: translateX(calc(-450px - 2rem));
}

.legend {
  background: #fff;
  padding: 0.75rem 1rem;
}

.legendItem {
  color: var(--color-a);
  display: flex;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}
.legendItem:last-child {
  margin-bottom: 0;
}

.legendItem > div {
  border-radius: 50%;
  height: 26px;
  margin-right: 0.5rem;
  width: 26px;
}

.divider {
  background: #fff;
  height: 1px;
  margin: 0.5rem 0;
  width: 100%;
}

.MapModeNav button {
  background-color: rgba(255, 255, 255, .03);
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  min-height: 132px;
  padding: 0.75rem;
  width: 100%;
}

.MapModeNav button:first-of-type {
  margin-top: 0.5rem;
}

.MapModeNav button img {
  height: 32px;
}

.MapModeNav button > div:first-of-type {
  font-family: var(--font-family-a);
  font-size: 0.75rem;
  font-weight: 300;
}
.MapModeNav button > div:last-of-type {
  font-family: var(--font-family-a);
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}


@media only screen and (max-width: 800px) {
  .MapModeNav {
    width: 100%;
    /* padding-left: 1px; */
    left: 0;
    top: 0;
    position: relative;
  }

  .legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:3px;
  }
  .legendItem {
    width:50%;
    font-size: small;
  }
  .legendItem > div {
    width: 10px;
    height: 10px;
    margin-top: 3px;
  }
  .collapse {
    transform: none;
  }
}
