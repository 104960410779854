.Map {
  height: 100%;
  width: 100%;
}

.panoActive {
  left: 0;
  top: 0;
  height: calc(100% - 65px);
  position: absolute;
  width: calc(100% - 350px);
}
.panoInactive{
  left: 0;
  top: 0;
  height: 0px;
  position: absolute;
  width: 0px;
}

.lot:hover {
  cursor: pointer;
  z-index: 3;
}

.lotPin:hover {
  cursor: pointer;
}

.lotText {
  cursor: pointer;
}

.hiddenLotText {
  opacity: 0;
}


@media only screen and (max-width: 800px) {
  .Map{
    position: relative;
    overflow: hidden;
    height:90vh;
  }
}
