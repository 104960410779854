.PrintPDF {
  background: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 12;
}

.imgWrapper {
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper {
  height: 100%;
  width: 100%;
}


.pdfLoading {
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  color: #000;
  display: flex;
  font-family: 'Brandon Grotesque';
  font-size: 3rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

/* .PrintPDF {
  background: #fff;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 12;
}

.imgWrapper {
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
} */