.ControlButton {
  align-items: center;
  background: rgba(255, 255, 255, .03);
  border-radius: 2px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 0.5rem 1rem;
  position: relative;
  width: calc(50% - 3.75px);
}

.ControlButton img {
  width: 24px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ControlButton span {
  flex-grow: 1;
  font-family: var(--font-family-a);
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 1rem;
  text-align: left;
  text-transform: uppercase;
}
