.Dropdown {
  display: flex;
  flex-direction: column;
}

.Dropdown header {
  /* align-items: center; */
  background-color: rgba(26, 52, 100, .08);
  display: flex;
  justify-content: space-between;
  padding: 0;
  border-radius: 2px;
  border-bottom: 2px solid #D5D9E2;
}

.Dropdown h4 {
  color: var(--color-a);
  font-family: var(--font-family-a);
  font-size: 1.625rem;
  margin: 0;
  margin-left: 1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.Dropdown button {
  background: none;
  border: none;
  color: #6d8bc9;
  cursor: pointer;
  text-decoration: underline;
}

.Dropdown ol {
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s linear;
  padding-inline-start: 0;
  background-color: white;
  border-bottom: 2px solid #D5D9E2;
}

.active ol {
  max-height: 15000px!important;
}

.Dropdown li {
  /* color: #f2f2f2; */
  cursor: pointer;
  list-style-type: none;
  display: flex;
  padding: .5rem;
  padding-left: 2rem;
}
/* .Dropdown li:nth-child(even) {
  opacity: .75;
} */

.Dropdown li:last-child {
  margin-bottom: 0;
}

.headingWrapper {
  align-items: center;
  display: flex;
  margin-left: 1.5rem;
  flex: 1 1 80%;
  padding: 5px;
  cursor: pointer;
}

.headingWrapperToggle {
  composes: headingWrapper;
  flex: 1 1 20%;
  margin-left: 0;
}

.headingDivider {
padding-right: .75rem;
padding-bottom: 3px;
margin: 0;
font-size: 30px;
font-weight: 150;
text-align: center;
color: rgba(0, 0, 0, .9);
}
