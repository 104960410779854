.Rating {
  align-items: center;
  color: #191919;
  display: flex;
  font-size: 0.875rem;
}

.RatingCard {
  font-size: 0.5rem;
  align-items: center;
  color: #191919;
  display: flex;
}

.starCard {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  background: #191919;
  display: block;
  height: 10px;
  margin-right: 0.1rem;
  width: 10px;
}

.star {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  background: #191919;
  display: block;
  height: 20px;
  margin-right: 0.25rem;
  width: 20px;
}

.star:first-child {
  margin-left: 0.25rem;
}

.fillNone {
  background: #191919;
}
.fillHalf {
  background: linear-gradient(90deg, #E4EB1C 50%, #191919 50%);
}
.fillFull {
  background: var(--color-b);
}
