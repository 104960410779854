.App {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100vw;
}

.buttonPress {
  height:100%;
  width: 50%;
  background-color: red;
}

.backButton {
  background-color: transparent;
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translateX(calc(-50% - 175px));
  z-index: 10;
  border: none;
  outline: none;
}

.container1 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.filterControlContainer {
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 68px;
  right: 355px;
}

.container2 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.menuWrapper {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  padding-bottom: 0.313rem;
  position: relative;
  width: 100%;
}

.test {
  width: 50vw;
  height: 50vh;
  position: absolute;
}

@media only screen and (max-width: 800px) {
  .App {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .container1 {
    height:100%;
    overflow: visible;
  }
  .toggleMenu {
    visibility: visible;
    display: block;
    background: rgba(255, 255, 255, .03);
    border: none;
    outline: none;
    height:30px;
    width:60px;
    color: #fff;
    flex-grow: 1;
    font-family: var(--font-family-a);
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
  .menuWrapper {
    justify-content: flex-start;
    overflow: visible;
  }
  .mobileMenuButton {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    background-color: var(--color-a);
    border: 2px solid #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
    outline: none;
    padding: 0.5rem;
    pointer-events: all;
  }
}
