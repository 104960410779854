.LocationDetails {
  background: #FFF;
  display: flex;
  flex-direction: column;
  left: 0;
  height: 100%;
  /* position: absolute; */
  overflow: hidden;
  top: 0;
  width: 100%;
}

.backButton {
  background-color: var(--color-b);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  margin-top: auto;
  flex-shrink: 0;
}

.backButton img {
  width: 28px;
}

.header {
  background: rgba(26, 52, 100, .08);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--color-a);
  font-family: var(--font-family-a);
  padding: 0.625rem 0.938rem;
}

.header h4 {
  font-size: 1.25rem;
  margin: 0 0 0.625rem 0;
}

.header div {
  color: #fff;
}

.photo {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  flex-shrink: 0;
  height: 200px;
  width: 100%;
}

.directionsWrapper {
  /* background: #191919;
  color: #fff; */
  overflow-y: auto;
}

.directionsWrapper table[class="adp-placemark"] {
  /* background: #1E8ADC;
  color: #fff; */
  font-size: 0.938rem;
  font-weight: 400;
  margin: 0;
}

.directionsWrapper div[class="adp-summary"] + div {
  padding: 0.625rem;
}

.directionsWrapper table[class="adp-directions"], .directionsWrapper div[class="adp-summary"] {
  /* color: #fff; */
  font-size: 0.938rem;
  font-weight: 400;
}

.directionsWrapper div[class="adp-summary"] {
  margin-top: 0.625rem;
  padding-left: calc(22px + 0.625rem);
}

.directionsWrapper table[class="adp-directions"] td[class="adp-substep"] {
  border: none;
}

.directionsWrapper div[class="adp-legal"] {
  display: none;
}

.content {
  background: #fff;
  /* border-bottom: 1px solid var(--color-a); */
  border-top: 1px solid var(--color-a);
  color: var(--color-a);
  height: 100%;
  padding: 1.25rem;
}

.detailsList {
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
}

.detailsList li {
  margin-bottom: 0.5rem;
}
.detailsList li:last-child {
  margin-bottom: 0;
}

.buttonContainer {
  background: #fff;
  padding: 0.625rem;
}

.buttonContainer button {
  background: var(--color-a);
}

.buttonA {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
.buttonA img {
  width: 25px;
}
