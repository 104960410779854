.loadingPage {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}

.messagewrapper {
  text-align: center;
}
.message {
  color: black;
  position: relative;
  font-family: 'Avenir';
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  top: calc(50vh - 1.5em - 30px);
  transform: translate(-50%, 100%);
}

.circles div {
  position: absolute;
  top: 50vh;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #4b4b4b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.circles div:nth-child(1) {
  left: 49%;
  animation: lds-ellipsis1 0.6s infinite;
}
.circles div:nth-child(2) {
  left: 49%;
  animation: lds-ellipsis2 0.6s infinite;
}
.circles div:nth-child(3) {
  left: 50%;
  animation: lds-ellipsis2 0.6s infinite;
}
.circles div:nth-child(4) {
  left: 51%;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
