.Menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  width: 350px;
  /* overflow: hidden; */
  margin-left: 0.313rem;
  padding-right: 0.625rem;
  pointer-events: none;
  position: relative;
}

.collapseWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  transition: 0.3s linear;
  overflow: hidden;
}
.collapse {
  height: 0;
}

.listCardContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.listCardButtons {
  outline: none;
}

.listCardIcons {
  pointer-events: all;
  width: 100%;
}

.buttonContainer {
  background-color: var(--color-a);
  border: 2px solid #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 0.5rem;
  outline: none;
  padding: 0.5rem;
  pointer-events: all;
}

.buttonContainer button:nth-child(odd) {
  margin-right: 7.5px;
}
.buttonContainer button:nth-child(2) {
  margin-bottom: 7.5px;
}
.buttonContainer button:first-child {
  margin-bottom: 7.5px;
}

.mapControlIcons {
  width: 100%;
  outline: none;
}

@media only screen and (max-width: 800px) {
  .Menu {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .collapseWrapper {
    justify-content: space-between;
    transition: none;
  }
  .VerticalNavWrapper {
    position:absolute;
    bottom:0;
    right:0;
  }
  .buttonContainer {
    margin-top: 0rem;
  }
}
