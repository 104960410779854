.ProductLine {
    color: var(--color-a);
    max-height: 70%;
    display: flex;
    flex-direction: column;
    height: 34rem;      
}

.Header {
    border-bottom: 1px solid #D5D9E2;
    font-weight: 300;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem; 
    padding-left: 1rem; 
}
