.lotMenu {
  color:rgb(255,255,255);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upperSection {
  display: flex;
  flex-direction: column;
}

.lotHeader {
  background: #fff;
  color: var(--color-a);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: var(--font-family-a);
  padding: 1rem;
}

.lotHeader > div:first-of-type {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.lotHeader > div:first-of-type h2 {
  font-size: 1.625rem;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.lotHeader > div:first-of-type h3 {
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
}

.price {
  color: var(--color-b);
  font-size: 1.25rem;
  font-weight: 600;
}

.price + div {
  font-size: 0.625rem;
  font-weight: 300;
  text-align: center;
}

.headerRow {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.lotPhoto {
  flex-shrink: 0;
  width: 100%;
  height: auto;
}

.modelSpecs {
  align-items: center;
  color: var(--color-a);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  font-size: 1.125rem;
  margin-top: 0.5rem;
}

.specDetail {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.specDetail img {
  width: 27px;
}

.backButton {
  background-color: var(--color-b);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  margin-top: auto;
  flex-shrink: 0;
}

.backButton img {
  width: 28px;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}

.menuButton {
  background: #fff;
  border: 1px solid var(--color-a);
  border-radius: 5px;
  color: var(--color-a);
  cursor: pointer;
  flex: 1;
  font-family: var(--font-family-a);
  font-size: 0.625rem;
  font-weight: 300;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.menuButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.menuButton:last-child {
  margin-right: 0;
}

.arrowIcon {
  display: flex;
}

.arrowIcon img {
  height: 2rem;
}

.buttonText {
  border-left: 1px solid rgb(255,255,255);
  height: 100%;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.optionList {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;
}

.optionList::-webkit-scrollbar {
  display: none;
}

.optionRow {
  background-color: #fff;
  border-bottom: 1px solid #D5D9E2;
  color: var(--color-a);
  cursor: pointer;
  font-family: var(--font-family-a);
  font-size: 1rem;
  font-weight: 500;
  padding: .7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textBox {
  width: 100%;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  flex-shrink: 0;
}

.extraMarketHomeData{
  margin-top:10px;
  margin-bottom:10px;
  width: 100%;
  align-items: center;
  font-size: 15px;
  flex-shrink: 0;
  color: grey;

}

.frameBackground {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: black;
  background-size: cover;
}

.frameHidden {
  display: none;
  z-index: -100;
}

.frameShown {
  position: fixed;
  z-index: 9998;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  border: none;
}

.matterportExit {
  position: absolute;
  height: 40px;
  width: 100px;
  z-index: 9999;
  left: 50%;
  top: 97%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
}

.floorplanMessage {
  font-size: 0.975rem;
  margin-top: 1.5rem;
  text-align: center;
}

.simplifiedLotTypeInfo{
  text-align: center;
  padding: 7px;
}

.lotTypeInfo {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.lotTypeInfo > div:first-child {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
}

.lotTypeInfo img {
  width: 16px;
}

.lotTypeInfo > div:last-child {
  font-family: var(--font-family-a);
  font-size: 0.875rem;
  font-weight: 300;
}
