.Submenu {
  background: #fff;
  border: 2px solid white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: 70%;
  max-height: 690px;
  pointer-events: all;
  position: relative;
  transition: 0.3s linear;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.collectionHeader {
  background-color: var(--color-a);
  color: #fff;
  font-family: var(--font-family-a);
  font-size: 1.625rem;
  font-weight: bold;
  text-align: center;
  flex-shrink: 0;
  padding: 1rem;
  border-bottom: 1px solid rgb(141, 141, 141);
}

.content {
  height: 100%;
  overflow-y: scroll;
}

.content::-webkit-scrollbar {
  width: 20px;
}

.content::-webkit-scrollbar-track {
  background: var(--color-a-light);
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
  background: var(--color-a);
  border-radius: 10px;
}

.filingOne {
  color: var(--color-a);
  width: 100%;
  cursor: pointer;
  height: 50px;
  margin-top: 7vh;
}

.filing {
  color: var(--color-a);
  cursor: pointer;
  font-family: var(--font-family-a);
  width: 100%;
  height: 50px
}

.filingText {
  margin-left: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


@media only screen and (max-width: 800px) {
  .Submenu {
    flex:1;
  }
  .content {

  }
  .collectionHeader {
    color: var(--color-a);
  }
}
