.ProductLineItem {
    border-bottom: 1px solid #D5D9E2;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem; 
    padding-left: 1rem;
    cursor: pointer;
}
.SelectedProductLineItem {
    border-bottom: 1px solid #D5D9E2;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem; 
    padding-left: 1rem;
    cursor: pointer;
    background-color: var(--color-a-light);
}
