.Button {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 100%;
}

.Button.backButton {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  justify-content: center;
  padding: 0.938rem 0;
}

.Button div:first-child {
  border-right: 1px solid #fff;
  align-items: center;
  display: flex;
  padding: 2px;
  width: 45px;
}

.Button.backButton div:first-child {
  border-right: none;
  margin-right: 0.625rem;
  width: 20px;
}

.Button img {
  width: 100%;
}

.Button div:last-child {
  font-size: 0.938rem;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.Button.backButton div:last-child {
  width: auto;
}