.cardBox {
  color: var(--color-a);
  margin: 0;
  background:#fff;
  border-bottom: 1px solid #D5D9E2;
}

.itemInfo {
  font-family: var(--font-family-a);
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  width: 100%;
}

.price {
  font-family: var(--font-family-a);
  font-size: 0.875rem;
  font-weight: 300;
  position: absolute;
  right: 0;
}

.cardIndex {
  margin-right: 5px;
}
