.photoOverlay {
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 350px);
  z-index: 6;
}

.img {
    display: flex;
    width: 100%;
    height: 100vh;
}

@media screen and (min-width: 667px) {
    .photoOverlay {
        flex: 1;
    }   
  }
