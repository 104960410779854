.Legend {
    position: absolute;
    z-index: 10;
    display: flex;
    width: 100%;
    justify-content: center;
}

.Legend img {
    width: 8rem;
    margin: 3rem .5rem;
}
