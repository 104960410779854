.editMode {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 10000;
  background-color: #fff;
  border: 3px solid red;
  padding:5px;
}
.editMode > div {
  display:flex;
}
.editMode label {
  width: 100px;
}
.editMode input {
  width: 300px;
}
