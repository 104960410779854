.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
  margin-right: 1rem;
}

.switch input { 
  cursor: pointer;
  position: absolute;
  opacity: 0;
  width: 70px;
  height: 50px;
  top: -20px;
  left: -20px;
}

.slider {
  background: #CECECE;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  top: -1px;
  background: var(--color-a);
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border-radius: 50%;
  box-shadow: 0 0 .5px rgba(0, 0, 0, .5);
}

input:checked + .slider {
  background: var(--color-b);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  box-shadow: -2px 0 4px rgba(0, 0, 0, .5);
}

