.VerticalNav {
    display: flex;
    flex-direction: column;
    pointer-events: all;
    cursor: pointer;
    margin-bottom: 7rem;
    outline: none;
    position: absolute;
    bottom: 1.5rem;
    right: 107%;
    width: 50px;
}

.collapse {
  margin-bottom: 0.5rem;
  right: 0;
  transition: 0.3s linear;
  transition-delay: 0.5s;
}

.VerticalNav button {
  align-items: center;
  background: #fff;
  border: none;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 65px;
}

.VerticalNav button:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.VerticalNav button:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.VerticalNav button img {
  width: 43%;
}


@media only screen and (max-width: 800px) {
    .VerticalNav {
        position:fixed;
        top: 30px;
        right: 30px;
        zoom: 70%;
    }

    .collapse {
        position:fixed;
        top: 30px;
        right: 30px;
        margin-bottom: 0.5rem;
        transition: 0s linear;
        transition-delay: 0s;
    }
}
